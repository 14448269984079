@font-face {
    font-family: 'Satoshi';
    font-weight: 400;
    src: url(../assets/fonts/Satoshi-Regular.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    font-weight: 600;
    src: url(../assets/fonts/Satoshi-Bold.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: "Satoshi";
    font-style: italic;
    font-weight: 600;
    src: url(../assets/fonts/Satoshi-BoldItalic.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    font-weight: 900;
    src: url(../assets/fonts/Satoshi-Black.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'DIN Condensed';
    font-weight: 700;
    src: url(../assets/fonts/DIN-Condensed-Bold.ttf) format('truetype');
    font-display: swap;
}
